<template>
  <button
    class="Tab"
    :class="{ isActive }"
    tabindex="0"
    type="button"
    @click="$emit('click', $event)"
    @keydown.enter="$emit('click', $event)">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    isActive: Boolean,
  },
}
</script>

<style lang="scss">
.Tab {
  border-bottom: 3px solid transparent;
  cursor: pointer;
  display: inline-block;
  font-size: $h5;
  font-weight: $semibold;
  line-height: 3rem;
  margin: 0;
  padding: 0 ($grid-unit / 2);
  @include lg {
    padding: 0 $grid-unit;
  }

  &:hover {
    color: $main;
  }

  // ------------ states ------------
  &.isActive {
    border-color: $main;
    color: $main;
  }

  // ------------ sizes ------------
  &.lg {
    font-size: $h4;
    line-height: 4rem;
  }
}
</style>

<template>
  <div class="ProgressBar">
    <div class="list">
      <ProgressBarStep
        v-for="(step, index) in steps"
        :key="step.name"
        v-bind="step">
        <div v-if="$slots[`step-${index + 1}`]" class="step-slot">
          <slot :name="`step-${index + 1}`" />
        </div>
      </ProgressBarStep>
    </div>
  </div>
</template>

<script>
import ProgressBarStep from './ProgressBarStep'

export default {
  components: { ProgressBarStep },
  props: {
    steps: Array,
  },
}
</script>

<style lang="scss">
.ProgressBar {
  padding-top: 1rem;
  @include lg {
    height: 10rem;
  }

  > .list {
    counter-reset: step;
  }
}
</style>

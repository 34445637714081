<template>
  <Card class="CardFancy">
    <Icon v-if="icon" class="icon" :name="icon" />
    <div v-if="title || $slots.title" class="head">
      <template v-if="title">
        <div class="title">
          {{ title }}
        </div>
        <div>
          {{ subtitle }}
        </div>
      </template>
      <slot name="title" />
    </div>
    <div v-if="$slots.default" class="body">
      <slot />
    </div>
    <div v-if="$slots.actions" class="actions">
      <slot name="actions" />
    </div>
  </Card>
</template>

<script>
export default {
  props: {
    icon: String,
    subtitle: String,
    title: String,
  },
}
</script>

<style lang="scss">
$_border-color-main: mix($main, $border-color);

.Card.CardFancy {
  border: 1px solid $border-color;
  // border-radius: $radius-lg;
  overflow: hidden;
  // padding: 1rem;
  position: relative;
  // @include md {
  //   padding: 1.5rem;
  // }
  // @include lg {
  //   padding: 2rem;
  // }

  > .icon {
    font-size: 8rem;
    opacity: 0.1;
    position: absolute;
    right: -2rem;
    top: -2rem;
  }

  > .head {
    padding-bottom: 1rem;

    > .title {
      font-size: $h3;
      font-weight: $semibold;

      > .Tag {
        vertical-align: top;
      }
    }
  }

  > .body {
    @include md {
      padding-top: 1rem;
    }

    > .Set {
      text-align: center;
    }
  }

  > .actions {
    padding-top: 0.5rem;

    > .padded {
      padding-top: 1rem;
    }
  }

  &:hover {
    border-color: $_border-color-main;
  }

  // size
  &.lg {
    font-size: $h4;
  }

  // style
  &.blank {
    border: 0;
    box-shadow: none;
  }

  &.danger {
    background: $danger-light;
    border-color: $danger;
    color: $danger-dark;
  }
}
</style>

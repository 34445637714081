<template>
  <CardDocument :document="projectDocument" :title="projectDocument.title">
    <Btn
      v-if="canDelete"
      slot="actions"
      class="ib sm inv no-border danger"
      icon="danger"
      :isLoading="isDeleting"
      @click="PROMPT({
        confirm: remove,
        text: 'Ar tikrai norite ištrinti šį projekto dokumentą?',
      })">
      Ištrinti
    </Btn>
  </CardDocument>
</template>

<script>
import CardDocument from 'components/CardDocument'
import { actions } from 'views/utils'

export default {
  mixins: [],
  components: { CardDocument },
  props: {
    canDelete: Boolean,
    projectDocument: Object,
  },
  data() {
    return {
      isDeleting: false,
    }
  },
  methods: {
    remove() {
      this.$wrap(
        this.DELETE_PROJECT_DOCUMENT(this.projectDocument),
        'isDeleting',
        'Dokumentas ištrintas',
      )
    },
    ...actions(
      'DELETE_PROJECT_DOCUMENT',
      'PROMPT',
    ),
  },
}
</script>

<style lang="scss">
</style>

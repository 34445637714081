<template>
  <div
    class="ProgressBarStep"
    :class="{ active: theme !== null, [theme]: true }">
    <div class="body">
      <Route v-if="route" :data-cy="`breadcrumb-${tag}`" :to="route">
        {{ name }}<br>
        {{ sub }}
      </Route>
      <div v-else>
        {{ name }}<br>
        {{ sub }}
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tag: String,
    name: String,
    message: String,
    sub: String,
    theme: String,
    route: Object,
  },
  computed: {
    icon() {
      return 'check'
    },
  },
}
</script>

<style lang="scss">
$_light-shade: #bebebe;

.ProgressBarStep {
  padding: 0.5rem 0;
  position: relative;
  @include lg {
    float: left;
    padding: 0;
    text-align: center;
    width: 19.98%;
  }

  &::before {
    background: $white;
    border: 2px solid $border-color;
    border-radius: 50%;
    color: $_light-shade;
    content: counter(step);
    counter-increment: step;
    display: inline-block;
    font-weight: $bold;
    height: 30px;
    line-height: 27px;
    margin: 0 auto 10px;
    position: relative;
    text-align: center;
    width: 30px;
    z-index: 2;
    @include lg {
      display: block;
    }
  }

  &::after {
    background: $border-color;
    height: 3px;
    left: -50%;
    position: absolute;
    top: 15px;
    width: 100%;
    z-index: 1;
    @include lg {
      content: ' ';
    }
  }

  > .body {
    display: inline-block;
    margin-left: 0.5rem;
    vertical-align: middle;
    @include lg {
      display: grid;
      margin: 0;
      min-height: 6.5rem;
    }

    > .step-slot {
      margin-top: 0.5rem;
      @include lg {
        align-items: flex-end;
        display: flex;
        justify-content: center;
      }

      .Btn {
        margin: 0.1rem;
      }
    }

    a {
      border-bottom: 0;
    }
  }

  &.pending {
    &::before {
      outline: $blue solid 0.4rem;
      outline-offset: -1px;
    }
  }

  // themes
  &.success {
    &::before {
      background: $success;
      border-color: $success;
      color: $white;
    }

    &::after {
      background: $success;
    }
    // + li:after {
    //   background: $info;
    // }
    // + li:before {
    //   background: $info;
    //   border-color: $info;
    // }
  }

  &.info {
    &::before {
      background: $info;
      border-color: $info;
      color: $white;
    }

    &::after {
      background: $info;
    }
  }

  &.danger {
    &::before {
      background: $danger;
      border-color: $danger;
      color: $white;
    }

    &::after {
      background: $danger;
    }
  }

  &.warning {
    &::before {
      background: $warning;
      border-color: $warning;
      color: $white;
    }

    &::after {
      background: $warning;
    }
  }

  &:first-child::after {
    left: 0;
    width: 50%;
  }
}
</style>

<template functional>
  <div :class="['Set', data.class, data.staticClass, props.theme, props.size]">
    <SetIcon
      v-if="props.icon"
      :class="[props.theme, props.size].filter(Boolean).join('')"
      :icon="props.icon" />
    <div class="body">
      <div class="name">
        {{ props.name }}
      </div>
      <div class="value">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import SetIcon from './SetIcon'
import Vue from 'vue'

Vue.component('SetIcon', SetIcon)

export default {
  props: {
    icon: String,
    name: String,
    size: String,
    theme: String,
  },
}
</script>

<style lang="scss">
.Set {
  display: flex;
  margin-bottom: 1rem;
  @include xl {
    margin-bottom: 0;
  }

  > .body {
    overflow: hidden;

    > .name {
      color: $text-color-lightish;
      font-size: $h6;
    }

    > .value {
      @include ellipsis;
      // font-weight: $semibold;
    }
  }

  // size
  &.lg {
    margin-bottom: 2rem;

    > .body {
      > .name {
        font-size: $h5;
      }

      > .value {
        font-size: $h5;
        @include lg {
          font-size: $h4;
        }
      }
    }
  }
}
</style>

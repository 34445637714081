<template>
  <div class="FormAdvancePayment">
    <Wrap>
      <Row
        v-for="(consumptionObject, index) in consumptionObjects"
        :key="consumptionObject.objectAddress"
        :class="{ objectInfoRow: index < consumptionObjects.length - 1 }"
        data-cy="esoObject">
        <Column :xs="11 + Number(consumptionObjects.length === 1)">
          <Row>
            <Column :md="6">
              <Field
                data-cy="objectAddressLabel"
                :disabled="disabled"
                :label="consumptionObjectsLabels[index].objectAddressLabel"
                :maxlength="200"
                :minlength="8"
                required
                type="text"
                :value.sync="consumptionObject.objectAddress" />
            </Column>
            <Column :md="3">
              <Field
                data-cy="objectNoLabel"
                :disabled="disabled"
                :label="consumptionObjectsLabels[index].objectNoLabel"
                :minlength="5"
                required
                :value.sync="consumptionObject.objectNo" />
            </Column>
            <Column :md="3">
              <Field
                data-cy="objectCapacityLabel"
                :disabled="disabled"
                :label="consumptionObjectsLabels[index].objectCapacityLabel"
                :max="reservation.capacity"
                :min="0.1"
                required
                :step="0.1"
                type="number"
                :value.sync="consumptionObject.objectCapacity" />
            </Column>
          </Row>
        </Column>
        <Column
          v-if="consumptionObjects.length > 1"
          class="delete"
          :xs="1">
          <Icon
            :class="{ labelPlaceholderMargin: consumptionObjectsLabels[index].hasLabels }"
            name="cross"
            @click="removeConsumptionObject(index)" />
        </Column>
      </Row>
      <div class="addObject">
        <Btn
          class="add ib"
          data-cy="addObject"
          :disabled="lastObjectHasEmptyAttributes || disabled"
          @click="addConsumptionObject">
          <Icon name="plus" /> Pridėti objektą
        </Btn>
      </div>
      <Notice v-if="capacitySum !== reservation.capacity" class="warning">
        <template v-if="capacitySum < reservation.capacity">
          Prašome paskirstyti visą užrezervuotą galią.
          <strong>
            Liko paskirstyti {{ capacityDiff }} kWh.
          </strong>
        </template>
        <template v-else>
          Paskirstyta galia ({{ capacitySum }} kWh) viršyja rezervuotą galią ({{ reservation.capacity }}) kWh.
        </template>
      </Notice>
    </Wrap>
  </div>
</template>

<script>
import { getters } from 'views/utils'

export default {
  props: {
    capacitySum: Number,
    consumptionObjects: Array,
    disabled: Boolean,
    reservation: Object,
  },
  computed: {
    capacityDiff() {
      return Number((this.reservation.capacity - this.capacitySum)
        .toFixed(1))
    },
    consumptionObjectsLabels() {
      return this.consumptionObjects.map((_, index) => {
        const hasLabels = index === 0 || !this.screenSizes.includes('md')

        return {
          objectAddressLabel: hasLabels ? 'Vartojimo Objekto adresas' : '',
          objectCapacityLabel: hasLabels ? 'Priskiriami kWh' : '',
          objectNoLabel: hasLabels ? 'Objekto numeris' : '',
          hasLabels,
        }
      })
    },
    lastObjectHasEmptyAttributes() {
      const { objectAddress, objectCapacity, objectNo } = this
        .consumptionObjects[this.consumptionObjects.length - 1]

      return !objectAddress || !objectNo || !objectCapacity
    },
    ...getters('screenSizes'),
  },
  methods: {
    addConsumptionObject() {
      this.$emit('update:consumptionObjects', [
        ...this.consumptionObjects,
        { objectAddress: null, objectCapacity: null, objectNo: null },
      ])
    },
    removeConsumptionObject(indexToOmit) {
      this.$emit('update:consumptionObjects', this
        .consumptionObjects
        .filter((_, index) => index !== indexToOmit),
      )
    },
  },
}
</script>

<style lang="scss">
.FormAdvancePayment {
  .objectInfoRow {
    border-bottom: 1px solid $border-color;
    margin-bottom: 0.75rem;
    padding-bottom: 0.5rem;
    @include md {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .addObject {
    text-align: right;
  }

  .Wrap {
    padding: 0 1.5rem;
  }

  .labelPlaceholderMargin {
    margin-top: 2.5rem;
  }

  .delete {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .Icon {
      color: $danger;
      cursor: pointer;
      font-size: 2rem;
      line-height: 3rem;
      margin-bottom: 1rem;
    }
  }

  .Notice.warning {
    margin: 1rem 0 0;
  }
}
</style>

<template>
  <CardFancy class="CardDocument" data-cy="file" :data-cy-file-id="document.fileId">
    <Row>
      <Column class="top" :sm="4">
        <div class="fileName">
          <Icon :name="icon" />
          {{ title }}
        </div>
        <slot />
      </Column>
      <Column class="actions" :sm="8">
        <div v-if="$slots.status" class="status">
          <slot name="status" />
        </div>
      </Column>
    </Row>
    <div class="bottom">
      <slot name="actions" />
      <Btn
        v-if="document.fileId"
        class="info download"
        data-cy="download"
        @click="DOWNLOAD_FILE({ id: document.fileId })">
        Atsisiųsti
      </Btn>
    </div>
  </CardFancy>
</template>

<script>
import CardFancy from 'components/CardFancy'
import { actions } from 'views/utils'

export default {
  components: { CardFancy },
  props: {
    document: Object,
    icon: { type: String, default: 'file' },
    title: String,
  },
  methods: actions('DOWNLOAD_FILE'),
}
</script>

<style lang="scss">
.CardFancy.CardDocument {
  padding: 1rem;

  > .body {
    margin: 0 1rem;
    padding: 0;

    // .Column.top {
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: center;
    //   margin-bottom: 1rem;
    // }

    .fileName {
      @include ellipsis;
      font-size: $h4;
      font-weight: $semibold;
      line-height: 3rem;
      text-align: center;
      @include md {
        text-align: left;
      }

      > .Icon {
        color: $main;
        margin-right: 0.25rem;
      }
    }

    .bottom {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: flex-end;
      margin-top: 1rem;
      @include sm {
        flex-direction: row;
      }

      > .Btn {
        width: unset;
      }

      > .Tag {
        @include md {
          align-self: start;
        }
      }
    }

    .actions {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      justify-content: flex-end;
      @include md {
        flex-direction: row;
      }

      .status {
        display: inline-block;
        margin-right: 1rem;
        text-align: lefet;

        .Set {
          margin: 0;
        }
      }

      .Btn {
        // margin: 0.25rem 1.5rem;
        vertical-align: top;
      }
    }
  }
}
</style>

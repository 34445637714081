<template functional>
  <Icon class="SetIcon" :name="props.icon" />
</template>

<script>
export default {
  props: {
    icon: String,
  },
}
</script>

<style lang="scss">
.SetIcon.Icon {
  border-radius: $radius-round;
  color: $info;
  display: inline-block;
  font-size: $h3;
  height: 2rem;
  line-height: 2rem;
  margin-right: 0.5rem;
  text-align: center;
  width: 2rem;
}

// size
.Set.lg > .SetIcon,
.SetIcon.lg {
  height: 2.5rem;
  line-height: 2.5rem;
  margin-top: 0.25rem;
  width: 2.5rem;
}

.Set.xl > .SetIcon,
.SetIcon.xl {
  font-size: $h2;
  height: 3rem;
  line-height: 3rem;
  width: 3rem;
}

// theme
.Set.success > .SetIcon,
.SetIcon.success {
  color: $success;
}

.Set.main > .SetIcon,
.SetIcon.main {
  color: $main;
}

.Set.danger > .SetIcon,
.SetIcon.danger {
  color: $danger;
}

.Set.info > .SetIcon,
.SetIcon.info {
  color: $info;
}
// .Set.warning > .SetIcon, .SetIcon.warning {
//   background: $warning-light;
//   color: $danger;
// }
</style>
